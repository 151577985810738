import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '~/components/seo'
import MainBlogPage from '../../components/mainBlogPage'

const BlogSingle = ({ data }) => {
  const article = data.contentfulBlog
  const currPostId = data.contentfulBlog.id
  const filterArticle = data.allContentfulBlog.edges

  const { title, content, slug, featuredImage } = article

  const ogp = featuredImage?.gatsbyImageData?.images?.fallback?.src

  return (
    <Layout>
      <Seo title={title} pageUrl={'blog/' + slug} description={content.excerpt} ogp={ogp} />
      <MainBlogPage article={article} currPostId={currPostId} filterArticle={filterArticle} />
    </Layout>
  )
}

export const query = graphql`
  query BlogPostBySlug($slug: String) {
    allContentfulBlog {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    contentfulBlog(slug: { eq: $slug }) {
      id
      title
      slug
      author {
        name
        featuredImage {
          gatsbyImageData
        }
        biography {
          childMarkdownRemark {
            html
          }
        }
      }
      featuredImage {
        gatsbyImageData
      }
      content {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 100, truncate: true)
        }
      }
      tags {
        slug
        title
      }
    }
  }
`

export default BlogSingle
